import React, { useEffect } from 'react';
import { Icon } from '@seeqdev/qomponents';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';

interface TextToSpeechProps {
  textToRead: string;
}
const synth = window.speechSynthesis;

const READING = 'Reading';
const STOPPED = 'Stopped';
export const TextToSpeech: React.FunctionComponent<TextToSpeechProps> = ({ textToRead }) => {
  const [status, setStatus] = React.useState(STOPPED);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (synth?.speaking || status === READING) {
        synth.cancel();
      }
    };
  }, []);

  const readOutLoud = () => {
    if (synth?.speaking || status === READING) {
      synth.cancel();
      return;
    }
    setStatus(READING);

    const utterThis = new SpeechSynthesisUtterance(textToRead);

    utterThis.onend = () => {
      setStatus(STOPPED);
    };

    utterThis.onerror = () => {
      setStatus(STOPPED);
    };

    synth.cancel();
    synth.speak(utterThis);
  };

  return (
    <div className="mr10 mb5 ml3">
      <Icon
        icon="fa-volume"
        extraClassNames={`cursorPointer ${status === READING ? 'fa-beat' : ''} ai-button`}
        type="text"
        tooltip={status === READING ? t('AI_ASSISTANT.STOP') : t('AI_ASSISTANT.READ')}
        onClick={readOutLoud}
      />
    </div>
  );
};
