import { Icon } from '@seeqdev/qomponents';
import React, { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';

const AvailableSpeechRecognition =
  window.SpeechRecognition ||
  window.webkitSpeechRecognition ||
  window.mozSpeechRecognition ||
  window.msSpeechRecognition;

const recognition = !AvailableSpeechRecognition ? null : new AvailableSpeechRecognition();

const LISTENING = 'Listening';
const STOPPED = 'Stopped';

interface SpeechToTextProps {
  setPrompt: (prompt: string) => void;
  stopListening?: boolean;
  resetStopListening: (val: boolean) => void;
}
export const SpeechToText: React.FunctionComponent<SpeechToTextProps> = ({
  setPrompt,
  stopListening,
  resetStopListening,
}) => {
  const [status, setStatus] = React.useState(STOPPED);

  useEffect(() => {
    if (stopListening) {
      setPrompt('');
      setStatus(STOPPED);
      recognition?.stop();
    }
  }, [stopListening]);

  if (!recognition) {
    return null;
  }

  recognition.interimResults = true;
  recognition.continuous = true;

  recognition.onstart = () => {
    setStatus(LISTENING);
  };

  recognition.onend = () => {
    setStatus(STOPPED);
    resetStopListening(false);
  };

  recognition.onresult = (event: any) => {
    const message = _.chain(event.results)
      .map((result: { transcript: string }[]) => result[0].transcript)
      .join(' ')
      .value();
    if (status !== STOPPED) {
      setPrompt(message);
    }
  };

  const manageSpeechToText = () => {
    if (status === STOPPED) {
      recognition.start();
    } else {
      recognition.stop();
    }
  };

  return (
    <div className="mr10 mb5 ml3">
      <Icon
        icon="fa-microphone"
        extraClassNames={`cursorPointer ai-button ${status === LISTENING ? 'fa-beat' : ''}`}
        type="text"
        onClick={manageSpeechToText}
      />
    </div>
  );
};
