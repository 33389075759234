import { doTrack } from '@/track/track.service';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { agents, AgentType, AssistantOrigin } from './aiAssistant.types';
import { AiGetStartedCarousel } from '@/aiAssistant/AiGetStartedCarousel.atom';
import { Icon } from '@seeqdev/qomponents';

type CannedPromptCategory = 'cleanse';
type CannedPrompt = AssistantOrigin | CannedPromptCategory;

interface AiAssistantIntroductionProps {
  origin: AssistantOrigin;
  submitPrompt: (prompt: string, origin: AssistantOrigin, context?: Record<string, unknown>) => void;
  selectedAgent: AgentType;
}

export const AiAssistantIntroduction: React.FunctionComponent<AiAssistantIntroductionProps> = ({
  origin,
  submitPrompt,
  selectedAgent,
}) => {
  const { t } = useTranslation();
  const [introPrompt, setIntroPrompt] = useState<CannedPrompt>(origin);
  useEffect(() => setIntroPrompt(origin), [origin]);

  const feelingLucky = (luckyPrefix: string, numberOfLuckies: number) => {
    const luckyIndex = _.random(1, numberOfLuckies); // if you add a LUCKY_X prompt to en.json, update this number
    submitPrompt(t(`AI_ASSISTANT.${luckyPrefix}_LUCKY_${luckyIndex}`), origin);
  };

  const promptsAndImage: Partial<
    Record<CannedPrompt, { prompt: string; sub: string; icon: string; action: () => void }[]>
  > = {
    workbench: [
      {
        prompt: 'AI_ASSISTANT.WB_PROMPT_1',
        sub: 'AI_ASSISTANT.WB_PROMPT_1_SUB',
        icon: 'fa-solid' + ' fa-wand-magic-sparkles',
        action: () => {
          setIntroPrompt('cleanse');
        },
      },
      {
        prompt: 'AI_ASSISTANT.WB_PROMPT_2',
        sub: 'AI_ASSISTANT.WB_PROMPT_2_SUB',
        icon: 'fa-magnifying-glass',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.PERIODS_OF_INTEREST`), origin);
        },
      },
      {
        prompt: 'AI_ASSISTANT.SURPRISE_ME_PROMPT',
        sub: 'AI_ASSISTANT.WB_PROMPT_3_SUB',
        icon: 'fa-solid fa-dice',
        action: () => {
          feelingLucky('WB', 5);
        },
      },
    ],
    cleanse: [
      {
        prompt: 'AI_ASSISTANT.CLEANSE_PROMPT_1',
        sub: 'AI_ASSISTANT.CLEANSE_PROMPT_1_SUB',
        icon: 'fc-series-gen',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.CLEANSE_PROMPT_1`), origin);
        },
      },
      {
        prompt: 'AI_ASSISTANT.CLEANSE_PROMPT_2',
        sub: 'AI_ASSISTANT.CLEANSE_PROMPT_2_SUB',
        icon: 'fc-display-range-unused',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.CLEANSE_PROMPT_2`), origin);
        },
      },
      {
        prompt: 'AI_ASSISTANT.CLEANSE_PROMPT_3',
        sub: 'AI_ASSISTANT.CLEANSE_PROMPT_3_SUB',
        icon: 'fc-prediction',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.CLEANSE_PROMPT_3`), origin);
        },
      },
    ],
    homescreen: [
      {
        prompt: 'AI_ASSISTANT.HOMESCREEN_PROMPT_1',
        sub: 'AI_ASSISTANT.HOMESCREEN_PROMPT_1_SUB',
        icon: 'fa-chart-line',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.HOMESCREEN_PROMPT_1_SUB`), origin);
        },
      },
      {
        prompt: 'AI_ASSISTANT.HOMESCREEN_PROMPT_2',
        sub: 'AI_ASSISTANT.HOMESCREEN_PROMPT_2_SUB',
        icon: 'fa-gauge-high',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.HOMESCREEN_PROMPT_2_SUB`), origin);
        },
      },
      {
        prompt: 'AI_ASSISTANT.SURPRISE_ME_PROMPT',
        sub: 'AI_ASSISTANT.HOMESCREEN_PROMPT_3_SUB',
        icon: 'fa-solid fa-dice',
        action: () => {
          feelingLucky('HOMESCREEN', 5);
        },
      },
    ],
    organizer: [
      {
        prompt: 'AI_ASSISTANT.ORGANIZER_PROMPT_1',
        sub: 'AI_ASSISTANT.ORGANIZER_PROMPT_1_SUB',
        icon: ' fa-circle-plus',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.ORGANIZER_PROMPT_1_SUB`), origin);
        },
      },
      {
        prompt: 'AI_ASSISTANT.ORGANIZER_PROMPT_2',
        sub: 'AI_ASSISTANT.ORGANIZER_PROMPT_2_SUB',
        icon: 'fa-share-all',
        action: () => {
          submitPrompt(t(`AI_ASSISTANT.ORGANIZER_PROMPT_2_SUB`), origin);
        },
      },
      {
        prompt: 'AI_ASSISTANT.SURPRISE_ME_PROMPT',
        sub: 'AI_ASSISTANT.ORGANIZER_PROMPT_3_SUB',
        icon: 'fa-solid fa-dice',
        action: () => {
          feelingLucky('ORGANIZER', 5);
        },
      },
    ],
  };

  const currentAgent = _.find(agents, { key: selectedAgent }!)!;
  return (
    <div className="flexColumnContainer flexFill promptsContainer">
      <div className="flexRowContainer flexFill flexCenter ">
        <div className="flexRowContainer flexFill flexCenter">
          <Icon
            icon={currentAgent.icon}
            type="color"
            color={currentAgent.color}
            extraClassNames="mb12 pl2 fa-2xl largeIcon"
          />
          <span className="mb15 ai-text" style={{ color: currentAgent.color }}>
            {currentAgent.name} Agent
          </span>

          <AiGetStartedCarousel selectedAgent={selectedAgent} />
        </div>
        <div className="flexRowContainer width-maximum flexCenter mb15 ">
          {promptsAndImage[introPrompt]?.map(({ prompt, sub, icon, action }, index: number) => {
            return (
              <div
                key={`wbPrompt_${index}`}
                className="flexRowContainer flexCenter promptTile m5 p5 cursorPointer"
                onClick={() => {
                  action();
                  doTrack('AiAssistant', 'intro prompt', { prompt });
                }}>
                <div className="flexColumnContainer flexCenter promptTileTitle">{t(prompt)}</div>
                <div className="ai-text">{t(sub)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
