// @ts-strict-ignore
import React, { useEffect, useRef, useState } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Icon } from '@seeqdev/qomponents';
import {
  debouncedImageStateChanged,
  removeContentDisplayMetadata,
  setContentShowWarningMessage,
} from '@/reportEditor/report.actions';
import { contentError, getContentURL } from '@/annotation/reportContent.utilities';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import { ContentMenu } from '@/annotation/ckEditorPlugins/components/ContentMenu.molecule';
import {
  buildAbsoluteContentUrl,
  buildRelativeContentUrl,
  getContentSpecificCommand,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import {
  CONTENT_MODEL_ATTRIBUTES,
  ContentCallbacks,
  ContentDisplayMode,
  CustomPlugin,
  ImageContentListenerCommand,
  UpdateContentSizeCallback,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { useCkListener } from '@/core/hooks/useCkListener.hook';
import {
  callIfInteractiveContentHandlesEvent,
  renderContentVisualization,
} from '@/annotation/ckEditorPlugins/components/content.utilities';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { Content, ContentDisplayMetadata } from '@/reportEditor/report.constants';
import { sqReportStore, sqWorkbenchStore, sqWorksheetStore } from '@/core/core.stores';
import { getWorkbenchAddress } from '@/utilities/utilities';
import { CallbackRef } from '@/utilities.types';

interface RefreshingContentProps {
  contentId: string;
  updateContentSize: UpdateContentSizeCallback;
  editor: any;
  isInView: boolean;
  displayMode: ContentDisplayMode;
  isExporting?: boolean;
  displayWrapperRef: CallbackRef;
  editorId: string;
}

export const BLANK_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';
const toPx = (value: number) => `${value}px`;

/**
 * This monitors the various inputs to a piece of content causes the content to refresh.
 */
export const RefreshingContent: React.FunctionComponent<RefreshingContentProps> = ({
  contentId,
  updateContentSize,
  editor,
  displayMode,
  isInView,
  displayWrapperRef,
  editorId,
}) => {
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState(getContentURL(displayMode, contentId));
  const [properties, setProperties] = useState(undefined);
  const [showMenu, setShowMenu] = useState(false);
  const target = useRef(null);

  const { t } = useTranslation();
  let model = null;
  if (editor && editor.config) {
    const contentCallbacks: ContentCallbacks = editor.config.get(CustomPlugin.Content).contentCallbacks;
    model = contentCallbacks.getCurrentModel(contentId);
  }

  const { showAssetSelectionWarnings } = useFlux(sqWorksheetStore);

  //region useCkListeners
  const useWidthPercent = !!useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.RESIZE, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT),
  );
  const height = _.toNumber(
    useCkListener(
      editor,
      getContentSpecificCommand(ImageContentListenerCommand.HEIGHT, contentId),
      model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT),
    ),
  );
  const width = _.toNumber(
    useCkListener(
      editor,
      getContentSpecificCommand(ImageContentListenerCommand.WIDTH, contentId),
      model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH),
    ),
  );
  const border = useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.BORDER, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.BORDER),
  );
  const noMargin = useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.NO_MARGIN, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.NO_MARGIN),
  );
  const propertyOverrides = useCkListener(
    editor,
    getContentSpecificCommand(ImageContentListenerCommand.PROPERTY_OVERRIDES, contentId),
    model?.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES),
  );
  //endregion

  const [refreshMeasurements, setRefreshMeasurements] = useState({
    height,
    width,
  });

  const updateMeasurements = (
    newHeight: number | undefined,
    newWidth: number | undefined,
    shouldRestrictContentHeight: boolean = undefined,
  ) => {
    updateContentSize(newWidth, newHeight, shouldRestrictContentHeight, model);
  };

  // The report store has a large number of updates that occur on every document update, but hashCodes only get set
  // every once in a while.
  const hashCode: string = useFluxPath(sqReportStore, () => sqReportStore.getContentById(contentId)?.hashCode);
  const showWarningMessage: boolean = useFluxPath(sqReportStore, () => sqReportStore.hasShowWarningMessage(contentId));
  const backupPreview = useFluxPath(sqReportStore, () => sqReportStore.backupPreview);
  const darkMode = useFluxPath(sqWorkbenchStore, () => sqWorkbenchStore.darkMode);
  const displayMetadata: ContentDisplayMetadata = sqReportStore.getContentDisplayMetadataById(contentId);
  const content: Content = sqReportStore.getContentById(contentId) ?? {};

  //region useEffects
  useEffect(() => () => removeContentDisplayMetadata(contentId), []);

  useEffect(() => {
    if (hashCode && displayMode !== ContentDisplayMode.PDF) {
      // Store content gets set to what is in the backend frequently, so we want to ignore anytime the hashcode is
      // cleared completely.
      const upToDateMetadata = sqReportStore.getContentDisplayMetadataById(contentId);
      if (!upToDateMetadata?.errorClass) {
        const newSrc = sqReportStore.getContentImageUrl(contentId);
        // onLoad event won't fire if src hasn't changed
        if (newSrc !== src) {
          !upToDateMetadata?.refresh?.silently && setLoading(true);
          setSrc(newSrc);
        }
      }

      !refreshMeasurements &&
        setRefreshMeasurements({
          width: target.current.clientWidth,
          height: target.current.clientHeight,
        });
    }
  }, [hashCode]);
  //endregion

  const extraClassNames = [];
  if (loading || displayMetadata?.errorClass) extraClassNames.push('minimumContentSize');
  if (!refreshMeasurements) extraClassNames.push('maximumHeightAndWidth');

  const style = refreshMeasurements
    ? {
        width: toPx(refreshMeasurements.width),
        height: toPx(refreshMeasurements.height),
      }
    : {
        maxWidth: useWidthPercent || !content?.isReact ? undefined : toPx(width),
        maxHeight: useWidthPercent || !content?.isReact ? undefined : toPx(height),
      };

  const getContentUrl = () => {
    return displayMode === ContentDisplayMode.PDF
      ? buildAbsoluteContentUrl(
          getWorkbenchAddress(),
          content,
          sqReportStore.getDateRangeById(content.dateRangeId),
          sqReportStore.getAssetSelectionById(content.assetSelectionId),
        )
      : buildRelativeContentUrl(contentId);
  };

  const display = renderContentVisualization(
    {
      src,
      contentId,
      noMargin,
      border,
      displayMode,
      loading,
      style,
      target,
      editorId,
      extraClassNames: extraClassNames.join(' '),
      errorClass: displayMetadata?.errorClass,
      error: displayMetadata?.error,
      onLoad: (properties?: { visualization: Visualization }) => {
        setLoading(false);
        setProperties(properties);
      },
      onMeasurementsReady: (width, height) => {
        setRefreshMeasurements(undefined);
        debouncedImageStateChanged();
        model.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES)?.showChartView
          ? updateMeasurements(content.height, content.width)
          : updateMeasurements(height, width);
      },
      onError: (error: string, errorCode: number) => contentError(contentId, error, errorCode),
      propertyOverrides,
      isInView,
      height,
      width,
      darkMode,
      updateContentMeasurements: ({ width, height, shouldRestrictContentHeight }) =>
        updateMeasurements(height, width, shouldRestrictContentHeight),
      showMenu: () => setShowMenu(true),
    },
    content?.isReact,
  );

  const displayWrapperProps = {
    'href': displayMode === ContentDisplayMode.PDF ? getContentUrl() : undefined,
    'className': 'contentWrapper noCopy inheritMinHeightAndHeight width-maximum',
    'id': `contentWrapper-${contentId}`,
    'onClick':
      displayMode === ContentDisplayMode.NORMAL
        ? (e) => {
            e.preventDefault();
            setShowMenu(true);
          }
        : (e) => {
            if (callIfInteractiveContentHandlesEvent(e.target as HTMLElement)) {
              e.preventDefault();
            } else {
              window.open(getContentUrl(), '_self');
            }
          },
    'data-testid': `content-wrapper-${contentId}`,
  };

  // Copying the HTML under an <a> makes all text be styled as links when pasted in another application. Using a div
  // prevents that, but we still want an a tag for view mode and pdfs.
  const displayWrapper =
    displayMode === ContentDisplayMode.NORMAL ? (
      <div ref={displayWrapperRef} {...displayWrapperProps}>
        {display}
      </div>
    ) : (
      <a
        ref={displayWrapperRef}
        style={{ textDecoration: 'none', color: 'unset' }}
        {...displayWrapperProps}
        onMouseDown={(e) => {
          if (callIfInteractiveContentHandlesEvent(e.target as HTMLElement)) {
            e.preventDefault();
          }
        }}>
        {display}
      </a>
    );

  return (
    <>
      {displayMode === ContentDisplayMode.NORMAL && !backupPreview && (
        <div data-testid="refreshingContentOverlay">
          <Overlay
            transition={false}
            placement="bottom"
            target={target.current}
            rootClose={true}
            show={showMenu}
            onHide={() => setShowMenu(false)}>
            <Popover id={`contentMenu-${contentId}`}>
              <ContentMenu
                contentId={contentId}
                closeMenu={() => setShowMenu(false)}
                isReact={content.isReact}
                properties={properties}
                updateMeasurements={updateMeasurements}
              />
            </Popover>
          </Overlay>
        </div>
      )}
      {content.screenshotWarning && showAssetSelectionWarnings ? (
        <div className="figureWithCaption inheritMinHeight">
          {showWarningMessage && (
            <div className="caption sq-alert-warning">
              {
                <Icon
                  icon="fa-warning m3"
                  type="warning"
                  tooltipPlacement="right"
                  testId="assetSelectionWarningMessageIcon"
                  tooltip={t('REPORT.CONFIG.ASSET_SELECTION_WARNING_TOOLTIP_COLLAPSE')}
                  onClick={() => setContentShowWarningMessage(contentId, false)}
                />
              }
              {content.screenshotWarning}
            </div>
          )}
          <div className="positionRelative inheritMinHeight">
            <div className="positionAbsolute z102">
              {!showWarningMessage && (
                <Icon
                  extraClassNames="iconLeft"
                  icon="fa-warning"
                  type="warning"
                  testId="assetSelectionWarningIcon"
                  tooltip={t('REPORT.CONFIG.ASSET_SELECTION_WARNING_TOOLTIP_EXPAND')}
                  tooltipPlacement="right"
                  onClick={() => setContentShowWarningMessage(contentId, true)}
                />
              )}
            </div>
            {displayWrapper}
          </div>
        </div>
      ) : (
        <> {displayWrapper} </>
      )}
    </>
  );
};
