import { SeeqNames } from '@/main/app.constants.seeqnames';
import { HtmlPortalNode } from 'react-reverse-portal';

export const CK_DROPDOWN_BUTTON_CLASS = 'ckEditorDropdownButton';

export const EDITABLE_CONTENT_MODEL_ATTRIBUTES = {
  WIDTH: SeeqNames.TopicDocumentAttributes.DataSeeqContentWidth,
  HEIGHT: SeeqNames.TopicDocumentAttributes.DataSeeqContentHeight,
  SHOULD_RESTRICT_CONTENT_HEIGHT: SeeqNames.TopicDocumentAttributes.DataSeeqRestrictContentHeight,
  BORDER: SeeqNames.TopicDocumentAttributes.DataSeeqContentBorder,
  NO_MARGIN: SeeqNames.TopicDocumentAttributes.DataSeeqContentNoMargin,
  WIDTH_PERCENT: SeeqNames.TopicDocumentAttributes.DataSeeqContentPercentWidth,
  PROPERTY_OVERRIDES: SeeqNames.TopicDocumentAttributes.DataSeeqContentPropertyOverrides,
};

export const CONTENT_ATTRIBUTE_UPCAST_CONVERSIONS = {
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES]: (json: string) => JSON.parse(json),
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.WIDTH]: (width: string) => parseInt(width, 10) || 0,
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.HEIGHT]: (height: string) => parseInt(height, 10) || 0,
};

export const CONTENT_ATTRIBUTE_DOWNCAST_CONVERSIONS = {
  [EDITABLE_CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES]: (properties: any) => JSON.stringify(properties),
};

export const CONTENT_MODEL_ATTRIBUTES = {
  ...EDITABLE_CONTENT_MODEL_ATTRIBUTES,
  DATA_SEEQ_CONTENT: SeeqNames.TopicDocumentAttributes.DataSeeqContent,
  PENDING: SeeqNames.TopicDocumentAttributes.DataSeeqContentPending,
};

export const DATE_RANGE_LABEL_ATTRIBUTES = {
  DATA_DATE_RANGE_ID: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeId,
  DATA_DATE_RANGE_FORMAT: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeFormat,
  DATA_DATE_RANGE_CONTENT: SeeqNames.TopicDocumentAttributes.DataSeeqDateRangeContent,
};

export const ASSET_SELECTION_LABEL_ATTRIBUTES = {
  ASSET_SELECTION_ID: SeeqNames.TopicDocumentAttributes.DataSeeqAssetSelectionId,
  ASSET_SELECTION_DEPTH_LEVEL: SeeqNames.TopicDocumentAttributes.DataSeeqAssetSelectionDepthLevel,
};

export const IFRAME_CONTENT_ATTRIBUTES = {
  DATA_IFRAME_CONTENT_URL: SeeqNames.TopicDocumentAttributes.DataSeeqIframeContentUrl,
  DATA_IFRAME_CONTENT_WIDTH: SeeqNames.TopicDocumentAttributes.DataSeeqIframeContentWidth,
  DATA_IFRAME_CONTENT_HEIGHT: SeeqNames.TopicDocumentAttributes.DataSeeqIframeContentHeight,
};

export enum CustomPlugin {
  JournalLink = 'JournalLink',
  FixedWidth = 'FixedWidth',
  Content = 'Content',
  PdfExport = 'PdfExport',
  SeeqPageBreak = 'SeeqPageBreak',
  ContentBorder = 'ContentBorder',
  CopyForEmail = 'CopyForEmail',
  WorksheetLink = 'WorksheetLink',
}

export interface BasePluginDependencies {
  annotationId: string;
  canModify: boolean;
  isPDF: boolean;
}

export interface PluginSetup {
  name: string;
  plugin: any;
  toolbar?: string;
  config?: string;
}

export type UpdateContentSizeCallback = (
  width: number | undefined,
  height: number | undefined,
  shouldRestrictContentHeight: boolean | undefined,
  modelElement: any,
) => void;

export interface ContentCallbacks {
  updateContentModelAndNode: (
    editor: any,
    newContentId: string,
    modelElement: any,
    portalNode: HtmlPortalNode,
    contentIdChanged?: boolean,
  ) => void;
  updateContentAttributeById: (editor: any, attribute: string, value: string, contentId: string, save: boolean) => void;
  getCurrentModel: (contentId: string) => any | undefined;
  updateContentSize: UpdateContentSizeCallback;
  updateContentAttributeWithoutSaving: (attribute: string, value: string, modelElement: any) => void;
  setUseCkCopyLogic: (useCkCopyLogic: boolean) => void;
}

// Event listeners in CK have priorities which affect the order they run in. 2 events listening to the `copy` event
// would run in the order they're added unless one has a higher priority than the other. Most CK internal listeners
// have a priority of 0 or 1, so the levels here are in reference to that.
export const CK_PRIORITY = {
  HIGH: 2,
};

export interface ImageContentStyles {
  width?: number;
  height?: number;
  useWidthPercent?: boolean;
  border?: boolean;
  noMargin?: boolean;
}

export enum ImageContentListenerCommand {
  RESIZE = 'seeqContentResize',
  WIDTH = 'seeqContentWidth',
  HEIGHT = 'seeqContentHeight',
  BORDER = 'seeqContentBorder',
  NO_MARGIN = 'seeqContentNoMargin',
  PROPERTY_OVERRIDES = 'seeqContentPropertyOverrides',
}

export const AttributeToListenTo: {
  [key in ImageContentListenerCommand]: string;
} = {
  [ImageContentListenerCommand.RESIZE]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT,
  [ImageContentListenerCommand.WIDTH]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.WIDTH,
  [ImageContentListenerCommand.HEIGHT]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.HEIGHT,
  [ImageContentListenerCommand.BORDER]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.BORDER,
  [ImageContentListenerCommand.NO_MARGIN]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.NO_MARGIN,
  [ImageContentListenerCommand.PROPERTY_OVERRIDES]: EDITABLE_CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES,
};

export enum ContentDisplayMode {
  PDF = 'PDF',
  VIEW_ONLY = 'VIEW_ONLY',
  NORMAL = 'NORMAL',
}

export const DATA_SEEQ_CONTENT = CONTENT_MODEL_ATTRIBUTES.DATA_SEEQ_CONTENT;
