import React, { useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ButtonWithPopover } from '@/core/ButtonWithPopover.molecule';
import { Icon } from '@seeqdev/qomponents';
import { Agent, agents, AgentType } from './aiAssistant.types';
import { doTrack } from '@/track/track.service';
import { genAIAgents } from '@/services/systemConfiguration.utilities';

interface AgentSelectorProps {
  selectedAgentId?: AgentType;
  showSelected: boolean;
  showAgentName?: boolean;
  extraClassNames?: string;
  onAgentChange: (agent: AgentType) => void;
  allowUpdate?: boolean;
  isRunning: boolean;
}

export const AgentSelector: React.FunctionComponent<AgentSelectorProps> = ({
  selectedAgentId,
  showSelected,
  extraClassNames,
  onAgentChange,
  showAgentName,
  allowUpdate = false,
  isRunning,
}) => {
  const { t } = useTranslation();
  const [selectedAgent, setSelectedAgent] = React.useState<Agent | undefined>(undefined);
  const availableAgents = genAIAgents()?.toLowerCase();

  useEffect(() => {
    if (allowUpdate) {
      const agent = _.find(agents, { key: selectedAgentId });
      setSelectedAgent(agent);
    }
  }, [allowUpdate, selectedAgentId]);

  return (
    <ButtonWithPopover
      label={
        <div className={`flexColumnContainer ${extraClassNames}`}>
          {showSelected && selectedAgent && selectedAgent.color && (
            <Icon
              icon={selectedAgent.icon}
              type="color"
              color={selectedAgent.color}
              extraClassNames="pl5 pr2 agentIconFontSize"
            />
          )}
          {showAgentName && <span className="pl5 ai-text">{selectedAgent?.name}</span>}
          <Icon
            icon="fa-angle-down"
            type="text"
            extraClassNames="text-opaque-onhover pt3 pl1 pr1"
            testId="openAgentSelection"
          />
        </div>
      }
      popoverConfig={{
        id: `agentSelectionPopover`,
        placement: 'bottom',
        wrapperClassNames: 'min-width-0',
      }}
      disabled={isRunning}>
      <div className="flexColumnContainer flexAlignCenter mb8 optionHeader">
        <Icon icon="fa-sparkles" iconPrefix="fa-solid" extraClassNames="pl5 pr2" type="text" />
        <span className="pr10">{t('AI_ASSISTANT.AGENT_SELECTION_PROMPT')}</span>
      </div>
      {agents
        .filter((agent) => availableAgents.includes(agent.key))
        .map((agentOption) => (
          <div
            key={agentOption.key}
            onClick={() => {
              doTrack('AiAssistant', 'Agent selected', {
                agent: agentOption.key,
                location: showAgentName ? 'within chat' : 'in prompt',
              });
              onAgentChange(agentOption.key);
            }}
            className={`flexColumnContainer flexAlignCenter p5 agentOption cursorPointer ${
              selectedAgentId === agentOption.key ? 'selectedAgentOption' : ''
            }`}>
            <Icon
              icon={agentOption.icon}
              testId={`${agentOption.icon}_icon`}
              extraClassNames="pl5 mr8 agentIconFontSize"
              type="color"
              color={agentOption.color}
            />
            {agentOption.name}
          </div>
        ))}
    </ButtonWithPopover>
  );
};
